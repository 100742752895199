
import { Options, Vue } from 'vue-class-component';

import requests from '@/requests';
import { PricingApi, PRICING_API_TITLE } from '@/interface/pricing.interface';

@Options({})
export default class APIPricing extends Vue {
  public PRICING_API_TITLE = PRICING_API_TITLE;

  public apiPricing: PricingApi | null = null;

  public loader = false;

  public definitions = [
    'CIMA - Complete Integrated Marketing Append',
    'CIMA Select - Complete Integrated Marketing Append Select Elements',
    'CIMA Cert - Complete Integrated Marketing Append Certification',
    'NCOA - National Change of Address',
    'PCOA - Proprietary Change of Address',
    'fe - Forward Email Append',
    'fp - Forward Phone Append',
    'fd - Forward Demographic/Lifestyle Append on Reverse Phone',
    'dn - Disconnect Processing on Reverse Phone',
    'Fe - Forward Email Append',
    'Fp - Forward Phone Append',
  ];

  public getPrice(api: keyof PricingApi): string {
    const match = [
      'address_fp_found_phone_landline_phones',
      'address_fp_found_phone_wireless_phones',
      'address_fe_valid_emails',
    ];
    const apiString = api.toString();

    const suffix = match.some((v) => v === api) ? 'Match' : 'Call (input)';

    return `$${this.apiPricing![api]} / ${suffix}`;
  }

  created(): void {
    requests.billing.getJobPricing()
      .then((res) => {
        this.apiPricing = res.data.api;
      })
      .finally(() => {
        this.loader = false;
      });
  }
}
